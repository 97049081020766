'use strict';

class AdmitadLCW {
    urlParams;

    static admitadAffiliateId = 2052;
    static admitadAffiliateKey = 'WHMCSAffiliateID';
    static admitadUTMKey = "admitad_uid";
    static admitadUTMValue = "admitad";
    static cookiesPrefix = "admitad_lcw_";
    static cookiesLifeTime = 30; //Days

    static removeCookiesArr = [
        'WHMCSAffiliateID'
    ];

    constructor(params = window.location.search) {
        this.#setUrlParams(params);

        if (this.#isUrlContainsAdmitadParams()) {
            this.#removeOtherAffiliateCookies();
            this.#setAdmitadCookies();
        }
    }

    static init() {
        return new AdmitadLCW();
    }

    static cookies(key, value = undefined, days = AdmitadLCW.cookiesLifeTime) {
        return Cookies.cookies(key, value, days);
    }

    #setAdmitadCookies() {
        Object.keys(this.urlParams).forEach((key) => {
            let value = this.urlParams[key];

            if (this.#isAdmitadCookie(key) || this.#isAdmitadCookie(undefined, value)) {
                AdmitadLCW.cookies(AdmitadLCW.cookiesPrefix + key, value, AdmitadLCW.cookiesLifeTime);
            }
        })

        AdmitadLCW.cookies(AdmitadLCW.admitadAffiliateKey, AdmitadLCW.admitadAffiliateId, AdmitadLCW.cookiesLifeTime);
    }

    #removeOtherAffiliateCookies() {
        AdmitadLCW.removeCookiesArr.forEach((key) => {
            Cookies.removeCookie(key)
        })
    }

    #isUrlContainsAdmitadParams() {
        return Object.keys(this.urlParams).includes(AdmitadLCW.admitadUTMKey)
            || Object.values(this.urlParams).includes(AdmitadLCW.admitadUTMValue);
    }

    #isAdmitadCookie(key = undefined, value = undefined) {
        if (value == undefined || ('' + value).length == 0) {
            return AdmitadLCW.admitadUTMKey == key;
        }

        if (key == undefined || ('' + key).length == 0) {
            return AdmitadLCW.admitadUTMValue == value;
        }


        return AdmitadLCW.admitadUTMValue == value && AdmitadLCW.admitadUTMKey == key;
    }

    #setUrlParams(params = null) {
        this.urlParams = Object.fromEntries(new URLSearchParams(params));
    }
}

module.exports = AdmitadLCW;
